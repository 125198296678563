<template>

  <waves />


  <section class="bg-white py-8 text-gray-800">
    <div class="container max-w-5xl mx-auto px-12">
      <div class="flex flex-wrap">

        <h2 class="w-full py-2 text-5xl font-bold leading-tight">
          Ein letzter Schritt!
        </h2>

        <div class="w-full mb-4">
          <div class="h-1 gradient w-1/2 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <div class="text-gray-600 text-xl py-5 md:w-4/5">
          <p>Du hast es fast geschafft!</p>
          <p>Nach deiner Anmeldung zur Fameversity Community müsstest du von uns eine E-Mail erhalten haben.Wir bitten dich, deine E-Mail-Adresse über den in der Mail enthaltenen Link zu bestätigen, denn nur so dürfen wir dich in unsere Community aufnehmen!</p>
          <p>Also, auf geht's, noch ein letzter Klick und dann kann's auch schon los gehen!</p>
          <p>Wir freuen uns auf dich!</p>
          <p class="pt-10"><a href="#" @click.prevent="this.$router.push('/');">Zurück zur Startseite.</a></p>
        </div>

      </div>
    </div>
  </section>






</template>

<script>
export default {
  name: "SuccessView"
}
</script>

<style scoped>
p {
  margin-bottom: 1em;
}

a {
  text-decoration: underline;
  color: #d53369;
}
</style>