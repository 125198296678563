const ConsentHandler = function() {

    var obj = this;
    var consentedVendors = [];
    var consentData = null;
    var fetchingPromise = false;

    function init() {
        console.log('consentedVendors from cookie', consentedVendors);
        fetchCustomVendorConsents()
    }

    this.openModal = function(id) {
        window._sp_.gdpr.loadPrivacyManagerModal(id);
    }


    // this.tcfApiEventListener = function(tcData, success) {
    //     console.log('SPPM tcDataIncoming', success, tcData);
    //
    //     tcData.eventStatus === 'tcloaded' && fetchCustomVendorConsents();
    //
    //     /**
    //      * user has completed the the consent dialogue
    //      * and made a decision
    //      */
    //     tcData.eventStatus === 'useractioncomplete' && fetchCustomVendorConsents().then(obj.redirectToTargetUrl);
    // }

    /**
     * fetching consent data from the tcfApi
     */
    function fetchCustomVendorConsents() {
        console.log('SPPM: fetchCustomVendorConsents()');

        if (fetchingPromise === false) {

            fetchingPromise = new Promise(function (resolve, reject) {

                window.__tcfapi('getCustomVendorConsents', 2, function (vendorConsents, success) {
                    if (success) {
                        console.log('SPPM: Successfully fetched TCF2 custom vendors, setting value...');
                        console.log('SPPM: vendorConsents: ', vendorConsents);

                        consentData = vendorConsents;

                        if (vendorConsents.consentedVendors) {
                            obj.setConsentedVendors(vendorConsents.consentedVendors);
                        }

                        resolve(vendorConsents);
                    } else {
                        console.log('SPPM: Unable to fetch TCF2 custom vendors, trying again later...');
                        reject('unable to fetch');
                    }

                    fetchingPromise = false;
                });

            });

        }

        return fetchingPromise
    }

    /**
     * Example: GoogleAnalytics -> 5e542b3a4cd8884eb41b5a72
     */
    this.vendorGrant = async function(vendorId) {
        console.log('SPPM: vendorGrant()', vendorId);
        var data = consentData || await fetchCustomVendorConsents();
        // console.log('SPPM: HEY HERE IS RESULT', data);

        // data.grants.filter(vendor => vendor._id === '5e952f6107d9d20c88e7c975')
        var granted = data.grants && data.grants[vendorId] && data.grants[vendorId].vendorGrant ? data.grants[vendorId].vendorGrant : false;

        console.log('SPPM: vendorGrant',granted, data.grants[vendorId]);

        if(granted)
            return Promise.resolve(true)
        else
            return Promise.reject(false)
    }


    this.getConsentedVendors = function() {
        return consentedVendors;
    }

    this.setConsentedVendors = function(vendors) {
        console.log('SPPM: setConsentedVendors()', vendors);
        consentedVendors = vendors;
        // document.cookie = consentedVendorsCookieName + '=' + JSON.stringify(vendors) + ';expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/';
    }

    // this.dataLayerPush = async function() {
    //     console.log('SPPM: dataLayerPush()');
    //
    //     // check if we are being redirected
    //     var params = new URLSearchParams(window.location.search)
    //     var targetUrl = params.get("targetUrl") ? decodeURIComponent(params.get("targetUrl")) : void 0;
    //     var oref = params.get("oref") ? atob(decodeURIComponent(params.get("oref"))) : void 0; // we need to base64 decode the original referrer, otherwise the akamai web application firewall (WAF) will be a problem
    //     var orefPushObject = void 0;
    //
    //     if(targetUrl && oref && "/consent" === window.location.pathname) {
    //         orefPushObject = {
    //             'event': 'redirected_to_consent_page',
    //             'originalReferrer': oref
    //         };
    //     }
    //     else if(oref && "/consent" !== window.location.pathname) {
    //         orefPushObject = {
    //             'event': 'redirected_from_consent_page',
    //             'originalReferrer': oref
    //         };
    //     }
    //
    //     if(orefPushObject) {
    //         console.log('SPPM: dataLayerPush pushing', orefPushObject);
    //         window.dataLayer.push(orefPushObject);
    //
    //     }
    //
    //     var data = consentData || await fetchCustomVendorConsents();
    //
    //     var analyticsConsent = data.grants && data.grants['5e542b3a4cd8884eb41b5a72'] && data.grants['5e542b3a4cd8884eb41b5a72'].vendorGrant ? data.grants['5e542b3a4cd8884eb41b5a72'].vendorGrant : false;
    //     var optimizeConsent = data.grants && data.grants['5eb2856cbb0c9a4439bfe303'] && data.grants['5eb2856cbb0c9a4439bfe303'].vendorGrant ? data.grants['5eb2856cbb0c9a4439bfe303'].vendorGrant : false;
    //
    //     var facebookConsent = data.grants && data.grants['5f1b2fbdb8e05c3057240f56'] && data.grants['5f1b2fbdb8e05c3057240f56'].vendorGrant ? data.grants['5f1b2fbdb8e05c3057240f56'].vendorGrant : false;
    //     var facebookPixelConsent = data.grants && data.grants['5fac56cd1ba05165880458ad'] && data.grants['5fac56cd1ba05165880458ad'].vendorGrant ? data.grants['5fac56cd1ba05165880458ad'].vendorGrant : false;
    //
    //     // var holaConsent = data.grants && data.grants['5ef4bb9089849115596a6ab2'] && data.grants['5ef4bb9089849115596a6ab2'].vendorGrant ? data.grants['5ef4bb9089849115596a6ab2'].vendorGrant : false;
    //     var holaConsent = false; // <- 2022.04.07 THP: hack to disable hola via Google Tag Manager
    //
    //     var dataLayerPushObject = {
    //         'event': 'consent_changed',
    //         'Google Analytics': analyticsConsent ? 'true' : 'false', // Wert wird true wenn alle purposes erfüllt sind und Google Analytics aktiviert ist (User Consent), ansonsten false
    //         'Optimize': optimizeConsent ? 'true' : 'false', // Wert wird true wenn alle purposes erfüllt sind und Google Analytics+Google Optimize aktiviert ist (User Consent), ansonsten false
    //         // 'HolaSpark': holaConsent ? 'true' : 'false',
    //         // 'Facebook': facebookConsent ? 'true' : 'false',
    //         'FacebookPixel': facebookPixelConsent ? 'true' : 'false'
    //     }
    //
    //     console.log('SPPM: dataLayerPush pushing', dataLayerPushObject);
    //
    //     window.dataLayer.push(dataLayerPushObject);
    // }

    init();
}


export default ConsentHandler;

