import { createApp } from "vue";
import { createPinia } from "pinia";
import VueGtag from "vue-gtag";
import {bootstrap} from "vue-gtag";

import ConsentHandler from "./scripts/consentHandler";
window.__consentHandler = new ConsentHandler();

import MetaPixel from "./scripts/metaPixel";
window.__metaPixel = new MetaPixel();

import TiktokPixel from "./scripts/tiktokPixel";
window.__tiktokPixel = new TiktokPixel();

import App from "./CA.vue";
import router from "./router";

import "./assets/main.css";
import Variant from "@variant/variant.json";

import EmailSignup from "@/components/EmailSignup.vue";
import Player from "@/components/Player.vue";
import Waves from "@/components/Waves.vue";

const app = createApp(App)
    .component('email-signup', EmailSignup)
    .component('player', Player)
    .component('waves', Waves);

app.use(createPinia());
app.use(router);
app.use(VueGtag, {
    // disableScriptLoad: true,
    config: {
        id: "G-S512GRZ4D9",
        params: {
            anonymize_ip: true
        }
    },
    // enabled: false,
    appName: Variant.meta.domain,
    // pageTrackerScreenviewEnabled: true,
    bootstrap: false
}, router);

//app.provide('$variant', Variant);  // Providing to all components during app creation
app.config.globalProperties.$variant = Variant;
app.config.globalProperties.$consent = window.__consentHandler;
app.config.globalProperties.$metaPixel = window.__metaPixel;
app.config.globalProperties.$tiktokPixel = window.__tiktokPixel;

// GA CONSENT
window.__consentHandler.vendorGrant("5e542b3a4cd8884eb41b5a72").then(function(){ // ga vendor

    console.log('okokok');

    bootstrap().then((gtag) => {
        console.log('GA BOOTSTRAPED!')
    })
}).catch(function(e){
    // no consent for ga
});

app.mount("#app");
