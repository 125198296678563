<script setup>
import PlaylistItem from "./PlaylistItem.vue";
// Import
import router from "@/router";
</script>

<template>

  <div class="flex flex-wrap md:px-12 mb-5">
    <div class="player w-full md:w-3/5 text-center" @click="router.push('/faq');$gtag.event('ButtonClick', {'event_category': 'Clicks', 'event_label': 'PlayerPlay'})">
      <div class="player-overlay">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
        <circle style="fill:#EBBA16;" cx="29" cy="29" r="29"/>
          <g>
	<polygon style="fill:#FFFFFF;" points="44,29 22,44 22,29.273 22,14 	"/>
            <path style="fill:#FFFFFF;" d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
		c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
		l-22,15C22.394,44.941,22.197,45,22,45z M23,15.893v26.215L42.225,29L23,15.893z"/>
  </g>
</svg>
      </div>
      <img class="w-full" src="@variant/course.jpg">
    </div>
    <div class="playlist w-full py-2 px-1 md:w-2/5 text-center">
      <PlaylistItem v-for="(item,index) in $variant.player.items" :index="index" :item="item"/>
    </div>
  </div>

</template>

<script>
export default {
  name: "Player",
  data: function() {
    return {
      items: [
        {label: 'INTRO', duration: '10:12'},
        {label: 'Creator Checkliste', duration: '10:12'},
        {label: 'Die richtige Plattform für dich', duration: '10:12'},
        {label: 'Geld verdienen über Social Media', duration: '10:12'}
      ]
    }
  }
}
</script>

<style scoped lang="scss">

.player {
  background: black;
  aspect-ratio: 16 / 9;
  cursor: pointer;
}

.playlist {
  background: rgba(211, 177, 101, 0.2);

}

.player-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;

  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 30%;
    height: 30%;
  }
}

</style>