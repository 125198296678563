<template>

  <waves />


  <section class="bg-white py-8 text-gray-800">
    <div class="container max-w-5xl mx-auto px-12">
      <div class="flex flex-wrap">

        <h2 class="w-full py-2 text-5xl font-bold leading-tight">
          Abmeldung Erfolgreich!
        </h2>

        <div class="w-full mb-4">
          <div class="h-1 gradient w-3/4 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <div class="text-gray-600 text-xl py-5 md:w-4/5">
          <p class="py-2">
            Du hast dich erfolgreich abgemeldet.
          </p>
          <p><a href="#" @click.prevent="this.$router.push('/');">Zurück zur Startseite.</a></p>
        </div>

      </div>
    </div>
  </section>






</template>

<script>
export default {
  name: "UnsubscribedView"
}
</script>

<style scoped>
p {
  margin-bottom: 1em;
}

a {
  text-decoration: underline;
  color: #d53369;
}
</style>