<template>

  <waves />


  <section class="bg-white py-8 text-gray-800">
    <div class="container max-w-5xl mx-auto px-12">
      <div class="flex flex-wrap">

        <h2 class="w-full py-2 text-5xl font-bold leading-tight">
          Fehler.
        </h2>

        <div class="w-full mb-4">
          <div class="h-1 gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <div class="text-gray-600 text-xl py-5 md:w-4/5">
          <p class="py-2">
            Text
          </p>
        </div>

      </div>
    </div>
  </section>






</template>

<script>
export default {
  name: "ErrorView"
}
</script>

<style scoped>

</style>