<template>
  <div class="item mx-1 mb-1 py-2 gradient text-white flex items-center" @click="this.$router.push('/faq');$gtag.event('ButtonClick', {'event_category': 'Clicks', 'event_label': 'PlaylistItem'})">
    <div class="play-icon w-5 mx-2">
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
        <circle style="fill:#EBBA16;" cx="29" cy="29" r="29"/>
        <g>
	<polygon style="fill:#FFFFFF;" points="44,29 22,44 22,29.273 22,14 	"/>
          <path style="fill:#FFFFFF;" d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
		c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
		l-22,15C22.394,44.941,22.197,45,22,45z M23,15.893v26.215L42.225,29L23,15.893z"/>
  </g>
</svg>
    </div>
    <span class="num px-1 font-bold">{{this.index+1}}.</span>
    <span class="label flex-1 px-2 text-left">{{this.item.label}}</span>
    <span class="time px-4 font-mono">{{this.item.duration}}</span>
  </div>
</template>

<script>
export default {
  name: "PlaylistItem",
  props: ['index','item']
}
</script>

<style scoped>
.item {
  /*background: orange;*/
  border-radius: 4px;
  opacity: 0.8;
  cursor: pointer;
}
</style>