const MetaPixel = function() {

    /**
     * Configuration
     * @type {Object}
     */
    const config = {
        debug: true,
        excludes: [],
    };

    const standardEvents = [
        "addpaymentinfo",
        "addtocart",
        "addtowishlist",
        "completeregistration",
        "contact",
        "customizeproduct",
        "donate",
        "findlocation",
        "initiatecheckout",
        "lead",
        "pageview",
        "purchase",
        "schedule",
        "search",
        "starttrial",
        "submitapplication",
        "subscribe",
        "viewcontent",
    ];



    function init() {

        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];

            __consentHandler.vendorGrant('5fac56cd1ba05165880458ad').then(function(){
                t=b.createElement(e);t.async=!0;
                t.src=v;
                s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)
            }).catch(function(e){
                console.log('SPPM: Meta Pixel disabled');
            });
        }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');

        fbq('init', '1047133212122122');
        fbq('track', 'PageView');

    }


    const _fbqEnabled = () => {
        if (typeof window.fbq === "undefined") {
            if (config.debug) {
                console.log(
                    "[Meta Pixel]: `window.fbq` is not defined, skipping"
                );
            }

            return false;
        }

        return true;
    };

    /**
     * Event tracking
     * @param  {String} name
     * @param  {object} [data={}]
     */
    this.event = (name, data = {}) => {
        if (!_fbqEnabled()) return;

        if (config.debug) {
            console.groupCollapsed(`[Meta Pixel] Track event "${name}"`);
            console.log(`With data: ${data}`);
            console.groupEnd();
        }

        if (!standardEvents.includes(name.toLowerCase()))
            this.query("trackCustom", name, data);
        else this.query("track", name, data);
    };

    /**
     * Submit a raw query to fbq, for when the wrapper limits user on what they need.
     * This makes it still possible to access the plain Analytics api.
     * @param mixed ...args
     */
    this.query = (...args) => {
        if (!_fbqEnabled()) return;

        if (config.debug) {
            console.groupCollapsed(`[Meta Pixel] Raw query`);
            console.log(`With data: `, ...args);
            console.groupEnd();
        }

        window.fbq(...args);
    };



    init()
}
export default MetaPixel;