<template>

  <waves />


  <section class="bg-white py-8 text-gray-800">
    <div class="container max-w-5xl mx-auto px-12">
      <div class="flex flex-wrap">

        <h2 class="w-full py-2 text-5xl font-bold leading-tight">
          Impressum
        </h2>

        <div class="w-full mb-4">
          <div class="h-1 gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <div class="text-gray-600 text-xl py-5 md:w-4/5">
          <p class="py-2">
            RTL2 Fernsehen GmbH & Co. KG<br>
            Lil-Dagover-Ring 1<br>
            82031 Grünwald
          </p>
          <p class="py-2">
            Fon: +49(0) 89-64 185 0
            Fax: +49(0) 89-64 185 9999
          </p>
          <p class="py-2">Geschäftsführung: Andreas Bartl</p>
          <p class="py-2">Verantwortlich für den Inhalt i.S.v. § 18 Abs. 2 MStV: Andreas Bartl</p>
          <p class="py-2">Amtsgericht München, HRA 71105, UStIdNr.: DE 167 400 442</p>
        </div>

      </div>
    </div>
  </section>






</template>

<script>
export default {
  name: "ImprintView"
}
</script>

<style scoped>

</style>