<script setup>
import { RouterLink, RouterView } from "vue-router";
import HelloWorld from "./components/HelloWorld.vue";
import LogoSVG from "./assets/fameversity2.svg?component";
import {throttle} from 'lodash';
</script>

<template>

  <nav id="header" class="fixed w-full z-30 top-0 transition" :class="headerClasses">
<!--    <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">-->
    <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">

      <div class="block md:hidden pl-4">
        <div class="p-1">
          <div class="w-6 h-6"></div>
        </div>
      </div>

      <div class="logo-container">
        <RouterLink to="/">
          <LogoSVG alt="Creator Academy" class="logo logo-top inline z-50" />
        </RouterLink>
      </div>



<!--      <div class="pl-4 flex items-center">-->
<!--        <a class="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">-->
<!--          <LogoSVG alt="Vite logo" class="logo inline h-20 z-50" />-->
<!--          CREATOR ACADEMY-->
<!--        </a>-->
<!--      </div>-->
      <div class="block md:hidden pr-4">
        <button @click="expandMenu = !expandMenu" id="nav-toggle" class="flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
          <svg class="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </button>
      </div>
      <div class="w-full flex-grow md:flex md:items-center md:w-auto -mb-2 mt-2 md:mt-0 md:bg-transparent bg-gray-300 text-black p-4 md:p-0 z-20 py-20 md:py-4" :class="{'hidden': !expandMenu}" id="nav-content">
        <ul class="list-reset md:flex justify-end flex-1 items-center text-xl text-center">
          <li class="mr-3">
            <RouterLink to="/" @click="expandMenu = false" class="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4">Kurse</RouterLink>
          </li>
          <li class="mr-3">
            <RouterLink to="/preise" @click="expandMenu = false" class="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4">Preise</RouterLink>
          </li>
          <li class="mr-3">
            <RouterLink to="/faq" @click="expandMenu = false" class="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4">FAQ</RouterLink>
          </li>
        </ul>
<!--        <button id="navAction" class="mx-auto lg:mx-0 hover:underline font-bold rounded-full lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out bg-white text-gray-800">-->
<!--          Login-->
<!--        </button>-->



      </div>

    </div>

<!--    <hr class="border-b border-gray-100 opacity-25 my-0 py-0">-->
  </nav>


  <RouterView />






  <footer class="bg-white border-t">
    <div class="container mx-auto px-8">
      <div class="w-full flex flex-col md:flex-row py-10 text-center md:text-left justify-center">
        <div class="flex-1 mb-6 text-black justify-center">
          <RouterLink to="/" class="">
            <!--Icon from: http://www.potlabicons.com/ -->
            <LogoSVG alt="Creator Academy" class="logo logo-footer w-1/2 mx-auto md:mx-0" />
          </RouterLink>
        </div>
        <div class="flex-1">
          <p class="uppercase text-gray-500 md:mb-6">Links</p>
          <ul class="list-reset mb-6">
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <RouterLink to="/faq" class="no-underline hover:underline text-gray-800 hover:text-pink-500">FAQ</RouterLink>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <RouterLink to="/preise" class="no-underline hover:underline text-gray-800 hover:text-pink-500">Preise</RouterLink>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <RouterLink to="/" class="no-underline hover:underline text-gray-800 hover:text-pink-500">Kurse</RouterLink>
            </li>
          </ul>
        </div>
        <div class="flex-1">
          <p class="uppercase text-gray-500 md:mb-6">Rechtliches</p>
          <ul class="list-reset mb-6">
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <RouterLink to="/impressum" class="no-underline hover:underline text-gray-800 hover:text-pink-500">Impressum</RouterLink>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <RouterLink to="/datenschutz" class="no-underline hover:underline text-gray-800 hover:text-pink-500">Datenschutz</RouterLink>
            </li>
          </ul>
        </div>


      </div>
    </div>
  </footer>


</template>

<script>
export default {
  name: "CA.vue",
  data() {
    return {
      stickyHeader: false,
      expandMenu: false,
    }
  },
  mounted() {
    window.addEventListener("scroll", throttle(this.onScroll,100), true);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", throttle(this.onScroll,100), true);
  },
  computed: {
    headerClasses() {
      return this.stickyHeader ? ['bg-white','shadow','text-gray-800','scrolled'] : ['text-white']
    }
  },
  methods: {

    onScroll() {

      if (window.scrollY > 10) {
        this.stickyHeader = true;
      }
      else {
        this.stickyHeader = false;
      }

      // if (this.$refs.introText.getBoundingClientRect().top < 50) {
      //   this.stickyHeader = true;
      // } else {
      //   this.stickyHeader = false;
      // }
    },

  }
}
</script>

<style scoped>
  .router-link-active {
    font-weight: bold;
  }
</style>