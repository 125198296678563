<script setup>
    import VueFriendlyCaptcha from '@somushq/vue3-friendly-captcha';
</script>
<template>



  <section class="container mx-auto text-center py-6 px-3 mb-12" :class="!!light ? 'text-white' : 'text-gray-800'">
    <h2 class="w-full my-2 text-5xl font-bold leading-tight text-center">
      Nicht verpassen!
    </h2>
    <div class="w-full mb-4">
      <div class="h-1 mx-auto w-64 opacity-25 my-0 py-0 rounded-t" :class="!!light ? 'bg-white' : 'gradient'"></div>
    </div>
    <h3 class="my-4 text-2xl leading-tight">
      <!--        Lass dich sofort benachrichtigen, sobald der Kurs verfügbar ist:-->
<!--      Melde dich hier mit deiner E-Mail Adresse an, und wir benachrichtigen dich sobald der Kurs verfügbar ist.-->
      Um als Erste/r informiert zu werden, wenn es losgeht, trage Dich jetzt hier ein:
    </h3>

    <form class="w-full md:w-4/6 mx-auto" action="https://web.inxmail.com/rtl2/subscription/servletv3" method="post">
      <div class="flex items-center flex-wrap justify-center">
        <input @focus="touched=true" class="py-4 px-8 mr-4 shadow appearance-none border rounded w-full md:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" name="email" placeholder="Deine @E-Mail Adresse">
        <input @click="touched=true" type="submit" class="md:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out" name="submit" value="Eintragen" />



        <input type="hidden" name="INXMAIL_SUBSCRIPTION" :value="$variant.meta.inxmailList"/>
        <input type="hidden" name="INXMAIL_HTTP_REDIRECT" :value="$variant.meta.success"/>
        <input type="hidden" name="INXMAIL_HTTP_REDIRECT_ERROR" :value="$variant.meta.error"/>
        <input type="hidden" name="INXMAIL_CHARSET" value="UTF-8"/>
<!--        <input type="hidden" :name="$variant.meta.inxmailVariant" value="true"/>-->

        <div v-show="touched" class="flex items-center flex-wrap justify-center">
          <label>
          <input type="checkbox" required name="OFF_INXMAIL_TRACKINGPERMISSION">
<!--          Ja, ich bin damit einverstanden, dass mein personenbezogenes Nutzungsverhalten im Newsletter erfasst und ausgewertet wird, damit die Inhalte besser auf meine persönlichen Interessen ausgerichtet werden können. Über einen Link im Newsletter kann ich diese Funktion jederzeit deaktivieren.<br>-->
            Ich möchte Neuigkeiten zum Fameversity-Angebot erhalten und stimme den Datenschutzrichtlinien zu.
          </label>

          <vue-friendly-captcha
              sitekey="FCMQMF2A9M34Q6HJ"
              puzzleEndpoint="https://eu-api.friendlycaptcha.eu/api/v1/puzzle"
              language="de"
              class="mt-5"
          />
        </div>



      </div>
    </form>

  </section>

</template>

<script>
export default {
  name: "EmailSignup",
  props: {
    light: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      touched: false
    }
  }
}
</script>

<style scoped>

</style>