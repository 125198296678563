import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PricingView from "../views/PricingView.vue";
import FaqView from "../views/FaqView.vue";
import ImprintView from "../views/ImprintView.vue";
import PrivacyView from "../views/PrivacyView.vue";
import SignedUpView from "../views/SignedUpView.vue";
import SuccessView from "../views/SuccessView.vue";
import UnsubscribedView from "../views/UnsubscribedView.vue";
import ErrorView from "../views/ErrorView.vue";
import Variant from "@variant/variant.json";


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // history: createWebHistory(Variant.meta.path),

  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/preise",
      name: "pricing",
      component: PricingView,
    },
    {
      path: "/faq",
      name: "faq",
      component: FaqView,
    },
    {
      path: "/impressum",
      name: "imprint",
      component: ImprintView,
    },
    {
      path: "/datenschutz",
      name: "privacy",
      component: PrivacyView,
    },

    {
      path: "/ein-letzter-schritt",
      name: "signedUp",
      component: SignedUpView,
    },

    {
      path: "/anmeldung-erfolgreich",
      name: "success",
      component: SuccessView,
    },

    {
      path: "/abmeldung-erfolgreich",
      name: "unsubscribed",
      component: UnsubscribedView,
    },

    {
      path: "/fehler",
      name: "error",
      component: ErrorView,
    },

    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import("../views/AboutView.vue"),
    // },
  ],
});

export default router;
